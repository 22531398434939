/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/prismjs@1.15.0/components/prism-bash.min.js
 * - /npm/prismjs@1.15.0/components/prism-graphql.min.js
 * - /npm/prismjs@1.15.0/components/prism-json.min.js
 * - /npm/prismjs@1.15.0/components/prism-jsx.min.js
 * - /npm/prismjs@1.15.0/components/prism-less.min.js
 * - /npm/prismjs@1.15.0/components/prism-swift.min.js
 * - /npm/prismjs@1.15.0/components/prism-typescript.min.js
 * - /npm/prismjs@1.15.0/components/prism-yaml.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
